import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import HeaderBar from './HeaderBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

type HeaderProps = {
  isAuthenticated: boolean;
  logout: () => void;
};

function Header({ isAuthenticated, logout }: HeaderProps) {
  // Hardcode login for now

  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HeaderBar logout={logout} isAuthenticated={isAuthenticated} />

      {/*<main className={classes.content}>*/}
      {/*  <div className={classes.toolbar} />*/}
      {/*</main>*/}
    </div>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default Header;
