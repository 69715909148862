import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import {Provider as ReduxProvider} from 'react-redux';
import store from './reducers';

import {ThemeProvider} from '@material-ui/core/styles';
import theme from './muiTheme';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './index.css';
import App from './App';

import Amplify from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ThemeProvider theme={theme}>
                <ReduxProvider store={store}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                            <App/>
                    </MuiPickersUtilsProvider>
                </ReduxProvider>
            </ThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);
