import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {v4 as uuid} from "uuid";
import {GraphQLResult} from "@aws-amplify/api";
import {ListQuotesQuery} from "../API";
import {products} from "../containers/QuoteForm";

export interface PayoutScheduleRow {
    fdd: number;
    loss: number;
}

export interface ClaimHistoryRow {
    year: number;
    fdd: number;
    loss: number;
    payout: number;
}

interface QuotePricing {
    premiumPerAcre: number;
    claimHistory: ClaimHistoryRow[],
    payoutSchedule: PayoutScheduleRow[],
}

interface Location {
    name: string;
    latitude: number;
    longitude: number;
}

interface Placement {
    insurer: string;
    expires_at: string;
}

export type QuoteStatus = "estimate" | "pending" | "submitted" | "firm offer";

export interface Quote {
    id: string;
    client: string;
    crop: string;
    product: string;
    isArchived?: boolean;
    isShared?: boolean;
    status: QuoteStatus;
    initiated_at: string;
    sumInsuredPerAcre: number;
    acreage: number;
    pricing?: QuotePricing;
    placement?: Placement;
    submittedTo: Placement[];
    site: Location;
    riskPeriod: {
        start: string,
        end: string,
    }
}

export interface Data {
    quotes: Quote[]
}

const baseLocation = {
    name: 'Acme 502',
    latitude: 39.64009,
    longitude: -121.81930,
};

const basePayoutSchedule: PayoutScheduleRow[] = [
    {fdd: 4.00, loss: 5},
    {fdd: 5.04, loss: 6.4},
    {fdd: 6.08, loss: 7.8},
    {fdd: 7.12, loss: 9.2},
    {fdd: 8.16, loss: 10.6},
    {fdd: 9.20, loss: 12.0},
    {fdd: 11.28, loss: 14.8},
    {fdd: 12.32, loss: 16.2},
    {fdd: 13.36, loss: 17.6},
];

export const baseQuote: Quote = {
    id: '9b1deb4d3b7d4bad9bdd2b0d7b3dcb6d',
    client: 'Acme Agro',
    crop: 'Almond',
    product: 'almond-frost',
    initiated_at: new Date("2021-01-10T10:00:00Z").toISOString(),
    sumInsuredPerAcre: 4932,
    acreage: 400,
    status: 'estimate',
    submittedTo: [],
    riskPeriod: {
        start: new Date("2021-02-11").toISOString(),
        end: new Date("2021-03-20").toISOString(),
    },
    pricing: {
        premiumPerAcre: 270.52,
        claimHistory: [
            {year: 2016, fdd: 0.0, loss: 0, payout: 0},
            {year: 2017, fdd: 4.88, loss: 5.00, payout: 125425.00},
            {year: 2018, fdd: 11.16, loss: 11.16, payout: 336139.00},
            {year: 2019, fdd: 12.79, loss: 12.79, payout: 406377.00},
            {year: 2020, fdd: 0.0, loss: 0, payout: 0},
        ],
        payoutSchedule: basePayoutSchedule,
    },
    site: baseLocation,
};

export const initialData: Data = {
    quotes: [
        {
            ...baseQuote,
            id: '11b6056dadd54645abd807f768358f69',
            status: 'pending',
            initiated_at: new Date("2021-01-13T10:50:00Z").toISOString(),
            pricing: undefined,
        }, {
            ...baseQuote,
            id: '5c6309604f2a4f3eb4879edc1d0ee032',
            status: 'firm offer',
            initiated_at: new Date("2021-01-13T10:50:00Z").toISOString(),
            sumInsuredPerAcre: 2550,
            isShared: true,
            pricing: {
                premiumPerAcre: 315.25,
                claimHistory: [
                    {year: 2016, fdd: 0.0, loss: 0, payout: 0},
                    {year: 2017, fdd: 4.88, loss: 5.00, payout: 125425.00},
                    {year: 2018, fdd: 11.16, loss: 11.16, payout: 336139.00},
                    {year: 2019, fdd: 12.79, loss: 12.79, payout: 406377.00},
                    {year: 2020, fdd: 0.0, loss: 0, payout: 0},
                ],
                payoutSchedule: basePayoutSchedule,
            },
            placement: {
                insurer: "AXA Climate",
                expires_at: new Date("2021-02-21T10:50:00Z").toISOString(),
            },
        },
        {
            ...baseQuote,
            id: '9a6f6b37ef7049f8978dff338fbaffa0',
            status: 'submitted',
            sumInsuredPerAcre: 3020,
            initiated_at: new Date("2021-01-13T10:50:00Z").toISOString(),
            submittedTo: [
                {
                    insurer: "AXA Climate",
                    expires_at: new Date("2021-02-21T10:50:00Z").toISOString(),
                },
                {
                    insurer: "MunichRe",
                    expires_at: new Date("2021-02-21T10:50:00Z").toISOString(),
                },
            ],
            site: {
                name: 'Acme 560',
                latitude: 40.64009,
                longitude: -121.81930,
            },
        },
        {
            ...baseQuote,
            client: 'Magnolia Inc.',
            id: '2a25bf1d89624843940980cd7bd81815',
            initiated_at: new Date("2021-01-11T11:50:00Z").toISOString(),
            sumInsuredPerAcre: 2010,
            isShared: true,
            pricing: {
                premiumPerAcre: 412.7,
                claimHistory: [
                    {year: 2016, fdd: 0.0, loss: 0, payout: 0},
                    {year: 2017, fdd: 4.88, loss: 5.00, payout: 125425.00},
                    {year: 2018, fdd: 11.16, loss: 11.16, payout: 336139.00},
                    {year: 2019, fdd: 12.79, loss: 12.79, payout: 406377.00},
                    {year: 2020, fdd: 0.0, loss: 0, payout: 0},
                ],
                payoutSchedule: basePayoutSchedule,
            },
            site: {
                name: 'Acme 570',
                latitude: 40.64009,
                longitude: -115.81930,
            },
        },

        {
            ...baseQuote,
            id: 'ebb1183e8d3a47159216e74e552d1ad9',
            initiated_at: new Date("2021-01-11T10:30:00Z").toISOString(),
            sumInsuredPerAcre: 2050,
            isShared: true,
            pricing: {
                premiumPerAcre: 372.45,
                claimHistory: [
                    {year: 2016, fdd: 0.0, loss: 0, payout: 0},
                    {year: 2017, fdd: 4.88, loss: 5.00, payout: 125425.00},
                    {year: 2018, fdd: 11.16, loss: 11.16, payout: 336139.00},
                    {year: 2019, fdd: 12.79, loss: 12.79, payout: 406377.00},
                    {year: 2020, fdd: 0.0, loss: 0, payout: 0},
                ],
                payoutSchedule: [
                    {fdd: 10.0, loss: 10},
                    {fdd: 11.2, loss: 11.2},
                ],
            }
        },
        {
            ...baseQuote,
            client: 'Magnolia Inc.',
            id: '2a25bf1d89624843940980cd7bd81816',
            initiated_at: new Date("2021-01-11T11:55:00Z").toISOString(),
            sumInsuredPerAcre: 2010,
            pricing: {
                premiumPerAcre: 410.7,
                claimHistory: [
                    {year: 2016, fdd: 0.0, loss: 0, payout: 0},
                    {year: 2017, fdd: 4.88, loss: 5.00, payout: 125425.00},
                    {year: 2018, fdd: 11.16, loss: 11.16, payout: 336139.00},
                    {year: 2019, fdd: 12.79, loss: 12.79, payout: 406377.00},
                    {year: 2020, fdd: 0.0, loss: 0, payout: 0},
                ],
                payoutSchedule: basePayoutSchedule,
            },
            site: {
                name: 'Acme 570',
                latitude: 40.64009,
                longitude: -115.81930,
            },
        },
        {
            ...baseQuote,
        },
    ],
}


interface QuotesState {
    byId: {
        [quoteId: string]: Quote;
    };
    clients: {
        [quoteId: string]: string;
    }
}

const initialState: QuotesState = {
    byId: {},
    clients: {},
};


const quotesSlice = createSlice({
    name: 'quotes',
    initialState: initialState as QuotesState,
    reducers: {
        setQuotes: (state, action: PayloadAction<Quote[]>) => {
            action.payload.forEach(x => {
                state.byId[x.id] = x;
                state.clients[x.client] = x.client;
            })
        },
        addQuote: (state, action: PayloadAction<Quote>) => {
            const id = uuid().replace('-', '');
            const quote = action.payload;
            state.byId[id] = {
                ...quote,
                id,
            };
            state.clients[quote.client] = quote.client;
        },
        archiveQuote: (state, action: PayloadAction<string>) => {
            state.byId[action.payload].isArchived = true;
        },
        enableSharing: (state, action: PayloadAction<string>) => {
            state.byId[action.payload].isShared = true;
        },
    }
});

export function dummyData(): Quote[] {
    return ["1", "2", "3"].map(id => ({
        ...baseQuote,
        id: id,
    } as Quote)) || []
}

// export function mapListQuotesQuery(listQuotes: GraphQLResult<ListQuotesQuery>): Quote[] {
//     console.log(listQuotes.data?.listQuotes?.items);
//     return listQuotes.data?.listQuotes?.items?.map(todo => ({
//         ...baseQuote,
//         id: todo?.id,
//         client: todo?.client,
//         product: todo?.product,
//         sumInsuredPerAcre: todo?.sumInsuredPerAcre,
//         isArchived: todo?.isArchived ?? false,
//         isShared: todo?.isShared ?? false,
//         initiated_at: todo?.initiated_at,
//         status: todo?.status,
//         riskPeriod: todo?.riskPeriod,
//         pricing: todo?.pricing,
//         site: todo?.site,
//         acreage: todo?.acreage,
//         submittedTo: todo?.submittedTo ?? [],
//         placement: todo?.placement ,
//         crop: products[todo!.product].cropId ,
//     } as Quote)) || []
// }

export const {addQuote, archiveQuote, enableSharing, setQuotes} = quotesSlice.actions

export const selectQuotesById = (state: QuotesState) => state.byId;
export const selectAllQuotes = (state: QuotesState) => Object.values(state.byId);
export const selectClients = (state: QuotesState) => Object.values(state.clients);

export default quotesSlice.reducer;
