import React, {useState} from 'react';

import {useDispatch} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Theme} from '@material-ui/core';
import {Magic} from 'magic-sdk';
import TextField from '@material-ui/core/TextField';
import {MAGIC_API_KEY} from '../env';
import {Dispatch} from 'redux';
import swapMagicToken from '../utils/login';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/browser';
import logo from '../components/worldcover_logo.png';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: 400,
    },
    email: {
        width: 350,
        marginBottom: 20,
    },
}));

const magic = new Magic(MAGIC_API_KEY);

async function magicLogin(
    email: string,
    dispatch: Dispatch<any>,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>,
    setLoading: Function,
) {
    if (!email) return;

    try {
        setLoading(true);
        const magicToken = await magic.auth.loginWithMagicLink({email});

        if (magicToken != null) {
            swapMagicToken(magicToken, dispatch, setError);
        } else {
            setError('Sorry! Something went wrong during login, please try again.');
        }
    } catch (e) {
        setError('Sorry! Something went wrong during login, please try again.');
        Sentry.captureException(e);
    } finally {
        setLoading(false);
    }
}

export default function LoginPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<string>();

    return (
        <div className="m-auto max-w-xl text-center">
            <div className="mt-16 mb-10 flex flex-col items-center space-y-2 justify-center">
                <img src={logo} className="h-16" alt="Worldcover Logo"/>
                <h2 className="text-4xl font-semibold">Quote Management Tool</h2>
            </div>
            <div className="p-8 bg-white border shadow-md rounded-md">
                <h2 className="text-3xl mb-4">Login</h2>
                <p className="mb-2">A login link will be sent to your email address</p>
                <TextField
                    id="filled-basic"
                    className={classes.email}
                    label="Email"
                    variant="filled"
                    value={email}
                    error={!!error}
                    helperText={error ? error : undefined}
                    onChange={(event) => setEmail(event.target.value)}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            // Do code here
                            magicLogin(email, dispatch, setError, setLoading);
                            ev.preventDefault();
                        }
                    }}
                />
                <br/>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => magicLogin(email, dispatch, setError, setLoading)}
                >
                    Log In
                    {isLoading ? (
                        <CircularProgress style={{marginLeft: '10px', height: '25px', width: '25px'}}/>
                    ) : null}
                </Button>
                <h4 className="mt-4">
                    Do you want to gain access to the Worldcover Quote Management Tool? Email us at{' '}
                    <a href="mailto:sales@worldcovr.com">sales@worldcovr.com</a>
                </h4>
            </div>
        </div>
    );
}
