import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'

import { API_ROOT } from '../env';
import { LOGOUT } from './user';
import { RootState } from '.';
import { AnyAction } from '@reduxjs/toolkit';

export const WEATHER_DATA_REQUEST = '@@weather/WEATHER_DATA_REQUEST';
export const WEATHER_DATA_SUCCESS = '@@weather/WEATHER_DATA_SUCCESS';
export const WEATHER_DATA_FAILURE = '@@weather/WEATHER_DATA_FAILURE';

export type WeatherDataPayload = {
  first_season_year: number;
  last_season_year: number;
  period: WeatherDataPeriod;
  latitude: number;
  longitude: number;
  product_code: string;
  data: IndexType[];
  settled_only: boolean;
};
export type IndexType = 'cdd' | 'crf' | 'crw' | 'fdd';

type WeatherDataPeriod = {
  period_length: number;
  start: {
    month: number;
    day: number;
  };
};

export type WeatherDataResponse = {
  source_metadata: {
    [id: string]: WeatherMetadata;
  };
  data: IndexDataRange[];
};

export type IndexDataRange = {
  year: number;
  cdd?: IndexDataPoint[];
  crf?: IndexDataPoint[];
  crw?: IndexDataPoint[];
  fdd?: IndexDataPoint[];
};

export type WeatherDataMap = {
  [id in WeatherQuantity]?: {
    metadata: WeatherMetadata;
    data: IndexDataPoint[];
  };
};

export type WeatherQuantity = 'temperature' | 'rainfall';
export type WeatherMetadata = {
  id: string;
  quantity: WeatherQuantity;
  res: number;
  time: 'hourly' | 'daily';
  unit: '°C' | '°F' | 'mm';
};

export type IndexDataPoint = {
  date: string;
  value: number;
};

export type IndexDataMap = {
  [idx in IndexType]?: IndexDataPoint[];
};

export const fetchWeatherData: any = (token: string, weatherData: WeatherDataPayload) => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/weather-data/indices`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(weatherData),
    method: 'POST',
    types: [WEATHER_DATA_REQUEST, WEATHER_DATA_SUCCESS, WEATHER_DATA_FAILURE],
  },
});

export type WeatherState = {
  isLoadingData: boolean;
  error?: any;
  byIndex: IndexDataMap;
  byQuantity: WeatherDataMap;
};

export const portfolioInitialState: WeatherState = {
  isLoadingData: false,
  error: undefined,
  byIndex: {},
  byQuantity: {},
};

export default function reducer(state = portfolioInitialState, action: AnyAction): WeatherState {
  switch (action.type) {
    case WEATHER_DATA_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoadingData: true,
      };
    case WEATHER_DATA_SUCCESS:
      const indexDefault: IndexDataMap = {
        cdd: [],
        crw: [],
        crf: [],
        fdd: [],
      };
      const indexData = (action.payload as WeatherDataResponse).data.reduce(
        (indexData: IndexDataMap, yrData: IndexDataRange) => {
          return {
            cdd: [...indexData.cdd, ...(yrData.cdd || [])],
            crf: [...indexData.crf, ...(yrData.crf || [])],
            crw: [...indexData.crw, ...(yrData.crw || [])],
            fdd: [...indexData.fdd, ...(yrData.fdd || [])],
          };
        },
        indexDefault,
      );

      const TEMPERATURE_SOURCE = 'chirps_usa_ca';
      const RAINFALL_SOURCE = 'era5_temp_h_usa_ca';

      const weatherData: WeatherDataMap = {
        temperature: {
          metadata: action.payload.source_metadata[TEMPERATURE_SOURCE],
          data: action.payload.source_data[0].data[TEMPERATURE_SOURCE],
        },
        rainfall: {
          metadata: action.payload.source_metadata[RAINFALL_SOURCE],
          data: action.payload.source_data[0].data[RAINFALL_SOURCE],
        },
      };

      return {
        ...state,
        isLoadingData: false,
        byIndex: indexData,
        byQuantity: weatherData,
      };
    case WEATHER_DATA_FAILURE:
      return {
        ...state,
        error: action.payload?.response as any,
        isLoadingData: false,
      };
    case LOGOUT:
      return {
        ...portfolioInitialState,
      };
    default:
      return state;
  }
}

export const getWeatherData = (state: RootState) => state.weather.byQuantity;
export const getIndexData = (state: RootState) => state.weather.byIndex;
export const isLoadingData = (state: RootState) => state.weather.isLoadingData;
