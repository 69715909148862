/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      id
      client
      product
      isArchived
      isShared
      status
      initiated_at
      sumInsuredPerAcre
      acreage
      pricing {
        premiumPerAcre
        claimHistory {
          year
          fdd
          loss
          payout
        }
        payoutSchedule {
          fdd
          loss
        }
      }
      placement {
        insurer
        expires_at
      }
      submittedTo {
        insurer
        expires_at
      }
      site {
        name
        latitude
        longitude
      }
      riskPeriod {
        start
        end
      }
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      id
      client
      product
      isArchived
      isShared
      status
      initiated_at
      sumInsuredPerAcre
      acreage
      pricing {
        premiumPerAcre
        claimHistory {
          year
          fdd
          loss
          payout
        }
        payoutSchedule {
          fdd
          loss
        }
      }
      placement {
        insurer
        expires_at
      }
      submittedTo {
        insurer
        expires_at
      }
      site {
        name
        latitude
        longitude
      }
      riskPeriod {
        start
        end
      }
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      id
      client
      product
      isArchived
      isShared
      status
      initiated_at
      sumInsuredPerAcre
      acreage
      pricing {
        premiumPerAcre
        claimHistory {
          year
          fdd
          loss
          payout
        }
        payoutSchedule {
          fdd
          loss
        }
      }
      placement {
        insurer
        expires_at
      }
      submittedTo {
        insurer
        expires_at
      }
      site {
        name
        latitude
        longitude
      }
      riskPeriod {
        start
        end
      }
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      cropId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      cropId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      cropId
      isActive
      createdAt
      updatedAt
    }
  }
`;
