import React, {useEffect, useState} from 'react';
import {Link,} from "react-router-dom";

import QuoteDetails, {getSharedUrl} from "../components/QuoteDetails";
import {products} from "./QuoteForm";
import {FormControl, InputLabel, MenuItem, Modal, Select} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useSelector} from "react-redux";
import {
    enableSharing,
    setQuotes,
    archiveQuote,
    Quote,
    QuoteStatus,
    initialData,
    dummyData
} from "../reducers/quotesSlice";
import {RootState, useAppDispatch} from "../reducers";
import moment from "moment";
import {formatDateTime} from "../utils";
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api";
import {listQuotes} from "../graphql/queries";
import {ListQuotesQuery} from "../API";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 8),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


function QuoteLabel(props: { quote: Quote, onClick: Function, isActive?: boolean, styles?: string }) {
    const {quote, onClick, isActive = false} = props;

    function statusPillBg(status: QuoteStatus) {
        switch (status) {
            case 'pending':
                return 'bg-gray-600';
            case 'estimate':
                return 'bg-green-600';
            case 'firm offer':
                return 'bg-yellow-600';
            case 'submitted':
                return 'bg-orange-500';
            default:
                return 'bg-green-600';
        }
    }

    return (
        <div onClick={() => onClick()}
             className={`p-2 border-b cursor-pointer hover:bg-gray-200 ${isActive ? 'bg-gray-200' : ''} ${props.styles}`}>
            <div className="flex justify-between items-center">
                {/*<div className=""> Quote #{quote.id} </div>*/}
                <div className=""> {quote.client} </div>

                <span
                    className={`px-2 py-1 text-xs text-white rounded ${statusPillBg(quote.status)}`}>{quote.status}</span>
            </div>
            <div className="mt-2">
                {products[quote.product].name} - {formatDateTime(quote.initiated_at)}
            </div>
            {quote.pricing &&
            <div className="mt-2 uppercase text-sm">$ {quote.pricing.premiumPerAcre.toLocaleString()}</div>}
        </div>);
}

function compareQuotes(a: Quote, b: Quote): number {
    if (moment(a.initiated_at).isBefore(b.initiated_at)) return 1;
    if (moment(a.initiated_at).isAfter(b.initiated_at)) return -1;
    return 0;
}

export default function QuotesPage() {
    const dispatch = useAppDispatch();

    const quotesById = useSelector((state: RootState) => state.quotes.byId);
    const allQuotes = useSelector((state: RootState) => Object.values(state.quotes.byId).sort(compareQuotes));

    const clients = useSelector((state: RootState) => Object.values(state.quotes.clients));

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);


    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedQuoteIndex, setSelectedQuoteIndex] = useState<number>(0);

    const [clientFilter, setClientFilter] = useState<string>('all');

    const filteredQuotes = allQuotes.filter(x => {
        if(x.isArchived) return false;
        return clientFilter === 'all' || x.client === clientFilter;
    });

    const currentQuote = filteredQuotes[selectedQuoteIndex];


    useEffect( () => {
        async function fetchData() {
            // const response = (await API.graphql(graphqlOperation(listQuotes))) as GraphQLResult<ListQuotesQuery>;
            const quotes: Quote[] = dummyData();
            dispatch(setQuotes(quotes));
        }

        fetchData();
    }, [dispatch]);

    function shareQuote(quote: Quote) {
        // make quote public
        dispatch(enableSharing(quote.id));

        // show popup with link
        setShowModal(true);
    }

    if (!currentQuote) return <div>Loading..</div>;

    function archive(currentQuote: Quote) {
        dispatch(archiveQuote(currentQuote.id));
    }

    return (
        <div className="m-auto max-w-6xl">
            <div>
                <h2 className="my-6 text-4xl font-semibold">All Quotes</h2>
            </div>
            <div className="flex mb-2">
                <div className="w-3/12 flex-none">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Filter client</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={clientFilter}
                            onChange={(event) => {
                                if (event.target.value) {
                                    setClientFilter(event.target.value as string);
                                }
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            {clients.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className="w-9/12 flex-none">
                    <div className="flex justify-end space-x-2 text-sm font-semibold">
                        <button
                            onClick={() => archive(currentQuote)}
                            className="px-6 py-4 bg-gray-600 text-white rounded shadow-md flex items-center space-x-2 uppercase">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"/>
                            </svg>
                            <div>Archive</div>
                        </button>
                        <Link to={`/new-quote?revise=${currentQuote.id}`}>
                            <button
                                className="px-6 py-4 bg-green-600  text-white rounded shadow-md flex items-center space-x-2 uppercase">
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                </svg>
                                <div>Revise</div>
                            </button>
                        </Link>
                        {currentQuote.pricing &&
                        <button
                            onClick={() => shareQuote(currentQuote)}
                            className="px-6 py-4 bg-green-600 text-white rounded shadow-md flex items-center space-x-2 uppercase">
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"/>
                            </svg>
                            <div>Share</div>
                        </button>}
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="w-3/12 flex-none">
                    {filteredQuotes.map((x, index) =>
                        <QuoteLabel key={x.id}
                                    quote={x}
                                    isActive={index === selectedQuoteIndex}
                                    onClick={() => setSelectedQuoteIndex(index)}
                        />)}
                </div>

                <div className="ml-4 w-9/12 h-screen overflow-y-auto">
                    <QuoteDetails quote={currentQuote}/>
                </div>
            </div>
            <Modal
                open={showModal}

                onClose={() => {
                    setShowModal(false)
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper} style={modalStyle}
                     id="simple-modal-description">
                    <h2 className="text-2xl">Share this quote</h2>
                    <div className="mt-4">
                        <a href={getSharedUrl(currentQuote)} target="_blank" rel="noopener noreferrer"
                           className="flex items-center space-x-1">
                            <svg className="w-4 h-4 inline-block"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"/>
                            </svg>
                            <div className="underline text-blue-600">{getSharedUrl(currentQuote)}</div>
                        </a>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
