import { Action, configureStore, combineReducers, ThunkAction } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
import profileReducer from './profile';
import userReducer from './user';
import weatherReducer from './weather';
import quotesReducer from './quotesSlice';
import {useDispatch} from "react-redux";

const rootReducer = combineReducers({
  profile: profileReducer,
  user: userReducer,
  weather: weatherReducer,
  quotes: quotesReducer,
})
export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});


export default store;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
