import React, {useContext, useEffect, useState} from 'react';
import {Link, Redirect, useParams} from "react-router-dom";

import QuoteDetails from "../components/QuoteDetails";
import {useSelector} from "react-redux";
import {RootState} from "../reducers";

export default function SharedQuote() {
    let {quoteId} = useParams();
    const quotesById = useSelector((state: RootState) => state.quotes.byId);

    const quote = quotesById[quoteId];

    if (!quote || !quote.isShared) {
        return <Redirect to="/login"/>
    }

    return (
        <div className="m-auto max-w-4xl">
            <QuoteDetails quote={quote} isPublic={true} className="mt-8"/>
        </div>
    );
}
