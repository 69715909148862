import React, {useState, useEffect} from 'react';
import {Redirect, Switch, Route, Link, useLocation} from 'react-router-dom';

import './App.css';
import './styles/main.css';

import Header from './components/Header';
import LoginPage from './containers/LoginPage';
import {useDispatch, useSelector} from 'react-redux';
import {isAuthenticated, logout as logoutFn} from './reducers/user';
import PrivateRoute from './components/PrivateRoute';
import QuotesPage from "./containers/QuotesPage";
import QuoteForm from "./containers/QuoteForm";
import SharedQuote from "./containers/SharedQuote";

const App = () => {
    const isAuth = useSelector(isAuthenticated);
    const dispatch = useDispatch();
    const logout = () => dispatch(logoutFn());

    return (
        <>
            <div className="">
                {isAuth ? <Header isAuthenticated={isAuth} logout={logout}/> : null}
                <Switch>
                    <Route path="/shared/:quoteId" component={SharedQuote}/>
                    <PrivateRoute path="/new-quote" component={QuoteForm}/>
                    <PrivateRoute path="/quotes" component={QuotesPage}/>
                    {isAuth ? <Redirect from="/" to="/quotes"/> : null}
                    {isAuth ? <Redirect from="/login" to="/new-quote"/> : null}


                    <Route path="/login" component={LoginPage}/>
                    {isAuth === false ? <Redirect to="/login"/> : null}

                </Switch>
            </div>
        </>
    );
};

export default App;
