import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export const LOGIN = '@@user/LOGIN';
export const LOGOUT = '@@user/LOGOUT';

type User = {
  id: number;
  username: string;
  role: string;
  name: string;
};

export type TokenData = {
  token: string;
  expires_at: number;
};

export type UserState = User & {
  lastUpdate?: Date;
  isAuthenticated: boolean;
  token?: string;
  authRedirect?: string;
  isLoadingData: boolean;
};

const loadToken = () => {
  return { token: '', isAuthenticated: true };
  
//   const tokenData = localStorage.getItem('apitoken');
//   if (tokenData === null) {
//     return { isAuthenticated: false };
//   } else {
//     const { expires_at, token }: TokenData = JSON.parse(tokenData);
//     const currentTimestamp = Math.floor(new Date().getTime() / 1000);
//     if (expires_at <= currentTimestamp) {
//       // Token expired, clear old token
//       localStorage.removeItem('apitoken');
//       return { isAuthenticated: false };
//     } else {
//       return { token, isAuthenticated: true };
//     }
//   }
  
};

const initialState = { ...loadToken(), isLoadingData: false } as UserState;

export default function reducer(state: UserState = initialState, action: AnyAction): any {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
      };
    case LOGOUT:
      return {
        isAuthenticated: false,
        isLoadingData: false,
      };
    default:
      return state;
  }
}

export const login = (token: string) => ({
  type: LOGIN,
  payload: { token },
});

export const logout = () => {
  localStorage.removeItem('apitoken');
  return {
    type: LOGOUT,
  };
};

export const getToken = (state: RootState) => state.user.token;
export const isAuthenticated = (state: RootState) => state.user.isAuthenticated;
