import React, {useEffect, useState} from 'react';
import ReactMapGL, {Marker} from 'react-map-gl';

import LocationOnIcon from '@material-ui/icons/LocationOn';

import {MAPBOX_TOKEN} from '../env';

type MapProps = {
    lat?: number;
    lng?: number;
    onClick?: (event: any) => void;
    width?: number;
    height?: number;
};

export default function LocationMap(props: MapProps) {
    const {lat, lng, onClick, height = 400} = props;

    const [viewport, setViewport] = useState({
        height: height,
        latitude: lat,
        longitude: lng,
        zoom: 12
    });

    useEffect(() => {
        setViewport({...viewport, latitude: lat, longitude: lng,})
    }, [lat, lng])

    const popup = (lat && lng &&
        <Marker latitude={lat} longitude={lng}>
            <LocationOnIcon style={{color: 'white'}}/>
        </Marker>
    );

    return (
        <ReactMapGL
            key="default-map"
            mapboxApiAccessToken={MAPBOX_TOKEN}
            mapStyle="mapbox://styles/mapbox/satellite-v9"
            onClick={onClick}
            maxZoom={15}
            width={'100%'}
            {...viewport}
            // latitude={lat}
            // longitude={lng}
            onViewportChange={nextViewport => setViewport(nextViewport)}
        >
            {popup}
        </ReactMapGL>
    );
}
