import React, {useEffect, useState} from "react";
import {Crop, crops, Product, products} from "../containers/QuoteForm";

function CropOption(props: { crop: Crop, isSelected?: boolean, isDisabled?: boolean, onClick: Function, className?: string }) {
    const {crop, isSelected = false, isDisabled = false, onClick} = props;

    return (
        <div onClick={() => onClick()}
             className={`flex-none px-6 py-3 rounded-lg border shadow bg-white cursor-pointer text-center ${isSelected ? 'border-2 border-green-500' : ''}`}>
            <h3 className="text-xl font-bold">{crop.name}</h3>
        </div>);
}

function ItemOption(props: { isSelected?: boolean, isDisabled?: boolean, onClick: Function, className?: string, children: React.ReactNode }) {
    const {isSelected = false, isDisabled = false, onClick, children} = props;

    return (
        <div onClick={() => onClick()}
             className={`flex-none px-6 py-3 rounded-lg border shadow bg-white cursor-pointer text-center ${isSelected ? 'border-2 border-green-500' : ''}`}>
            {children}
        </div>);
}

export interface ProductSelectorProps {
    value: string | null;
    styles?: string;

    onChange(product: string | null): void;
}

export function ProductSelector(props: ProductSelectorProps) {
    const {value, onChange} = props;
    const initialProduct = value == null ? null : products[value];


    const [selectedCropId, setSelectedCrop] = useState<string>('walnut');
    const [selectedProductId, setSelectedProduct] = useState<string | null>(null);

    // runs once
    useEffect(() => {
        if (initialProduct) {
            console.log('initialProduct', initialProduct);
            setSelectedCrop(initialProduct.cropId);
            setSelectedProduct(initialProduct.id);
        }
    }, [initialProduct])

    // // cancel product selection when crop changes
    // useEffect(() => {
    //     setSelectedProduct(null);
    // }, [selectedCropId])

    useEffect(() => {
        onChange(selectedProductId);
    }, [selectedProductId])

    useEffect(() => {
        console.log('selectedCropId', selectedCropId);
        console.log('selectedProductId', selectedProductId);
    }, [selectedCropId, selectedProductId])

    return <>
        <div>
            <h2 className="text-2xl">Crop</h2>
            <div className="mt-4 flex space-x-4 justify-start">
                {Object.values(crops).map((x, index) => <CropOption key={index} crop={x}
                                                                    isSelected={selectedCropId === x.id}
                                                                    onClick={() => setSelectedCrop(x.id)}/>)}

            </div>
        </div>
        <div className="mt-4">
            <h2 className="text-lg">Peril</h2>
            <div className="mt-1 flex space-x-4">

                {crops[selectedCropId].products.map((productId: any, index: number) => {
                    const product = products[productId];

                    return product &&
                        <ItemOption key={index}
                                    isSelected={selectedProductId === productId}
                                    onClick={() => setSelectedProduct(productId)}>
                            <div>{product.name}</div>
                        </ItemOption>
                })}
            </div>
        </div>

        <div className="mt-8">
            <h2 className="text-sm uppercase">Selected Product</h2>
            {selectedProductId ?
                <div className="mt-2 text-xl font-bold">
                    CropAssure {products[selectedProductId]?.name}
                </div> :
                <div className="mt-2 text-xl font-bold">None</div>
            }
        </div>
    </>;
}
