import React, {useContext, useEffect, useState} from 'react';

import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import LocationMap from "./LocationMap";
import {products} from "../containers/QuoteForm";
import {formatDate, formatDateTime} from "../utils";
import {ClaimHistoryRow, PayoutScheduleRow, Quote} from "../reducers/quotesSlice";

function Info(props: { label: string, value: string, styles?: string }) {
    return (
        <div className={props.styles}>
            <div className="uppercase text-xs">{props.label}</div>
            <div className="text-base">{props.value}</div>
        </div>);
}

function ClaimHistoryTable(props: { claimHistory: ClaimHistoryRow[], className?: string }) {
    const {claimHistory, className} = props;

    return (
        <table className={`table border-collapse border border-gray-500 ${className}`}>
            <thead>
            <tr>
                <th className="border-b border-gray-500 p-2 text-left">Year</th>
                <th className="border-b border-gray-500 p-2 text-right">FDD</th>
                <th className="border-b border-gray-500 p-2 text-right">Modelled Loss</th>
                <th className="border-b border-gray-500 p-2 text-right">Payout</th>
            </tr>
            </thead>
            <tbody>
            {claimHistory.map(x => (
                <tr key={x.year}>
                    <td className="p-2 text-left">{x.year}</td>
                    <td className="p-2 text-right">{x.fdd.toFixed(2)}</td>
                    <td className="p-2 text-right">{x.loss.toFixed(2)} %</td>
                    <td className="p-2 text-right">$ {x.payout.toLocaleString()}</td>
                </tr>
            ))}
            </tbody>
        </table>);
}

function PayoutScheduleTable(props: { rows: PayoutScheduleRow[], className?: string }) {
    const {rows, className} = props;

    return (
        <table className={`table border-collapse border border-gray-500 ${className}`}>
            <thead>
            <tr>
                <th className="border-b border-gray-500 px-2 py-1 text-right">Frost Degree Day</th>
                <th className="border-b border-gray-500 px-2 py-1 text-right">Payout (pct. of SI)</th>
            </tr>
            </thead>
            <tbody>
            {rows.map((x, index) => (
                <tr key={index}>
                    <td className="px-2 py-1 text-right">{x.fdd.toFixed(2)}</td>
                    <td className="px-2 py-1 text-right">{x.loss.toFixed(2)} %</td>
                </tr>
            ))}
            </tbody>
        </table>);
}

export function getSharedUrl(quote: Quote) {
    const baseUrl = process.env.REACT_APP_BASE_URL ?? 'https://quotes.worldcovr.com';
    return `${baseUrl}/shared/${quote.id}`
}

export default function QuoteDetails(props: { quote: Quote, isPublic?: boolean, className?: string }) {
    const {quote, isPublic = false} = props;
    const [showIndexDetails, setShowIndexDetails] = useState<boolean>(false);

    return (
        <div className={' ' + props.className}>

            <div className="bg-gray-200 p-8 rounded">
                {(quote.isShared && !isPublic) &&
                <div className="mb-8">
                    <a href={getSharedUrl(quote)} target="_blank" rel="noopener noreferrer"
                       className="flex items-center space-x-1 justify-end">
                        <svg className="w-4 h-4 inline-block"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"/>
                        </svg>
                        <div className="underline text-blue-600">{getSharedUrl(quote)}</div>
                    </a>
                </div>
                }
                <h2 className="text-2xl">
                    CropAssure™ {products[quote.product].name} {formatDateTime(quote.initiated_at)}
                </h2>

                {quote.placement &&
                <div className="mt-8">
                    <h2 className="text-xl">
                        Placed with: <span
                        className="px-2 py-1 rounded bg-gray-300 text-base">{quote.placement.insurer}</span>
                    </h2>
                </div>
                }

                {quote.submittedTo.length > 0 &&
                <div className="mt-8">
                    <h2 className="text-xl">
                        Submitted to: {quote.submittedTo.map(x => <span key={x.insurer}
                                                                        className="ml-2 px-2 py-1 rounded bg-gray-300 text-base">{x.insurer}</span>)}
                    </h2>
                </div>
                }
                <div className="mt-10 flex justify-between text-left">
                    <Info label="Crop" value="Almond"/>
                    <Info label="SUM INSURED / ACRE" value={`$ ${quote.sumInsuredPerAcre.toLocaleString()}`}/>
                    <Info label="SUM INSURED"
                          value={`$ ${(quote.sumInsuredPerAcre * quote.acreage).toLocaleString()}`}/>
                    <Info label="Coverage Period" value={`${formatDate(quote.riskPeriod.start)} to ${formatDate(quote.riskPeriod.end)}`}/>
                    {quote.pricing && <Info label="Total Premium" value={`$ ${(quote.pricing.premiumPerAcre * quote.acreage).toLocaleString() }`}/>}
                </div>

                {quote.pricing == null ? (<div className="text-center my-16">
                        <div className="text-lg">Quote being processed ...</div>
                    </div>) :
                    (
                        <div className="transition duration-500 ease-in">
                            <div className="text-xl text-center mt-12 mb-4 font-bold">
                                <span
                                    className="py-2 px-4 rounded-lg bg-yellow-200">PREMIUM: $ {quote.pricing.premiumPerAcre.toLocaleString()} / acre</span>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <button className="underline"
                                            onClick={() => setShowIndexDetails(!showIndexDetails)}>
                                        {showIndexDetails ? 'Hide' : 'Show'} index calculation details
                                    </button>
                                </div>

                                {showIndexDetails &&
                                <div className="mt-4">
                                    <Info styles="mt-4" label="WEATHER INDEX"
                                          value="Sum of daily FDD, with Daily FDD = Min ( Daily Cap ; Max( 0 ; FDD Threshold - Tmin))"/>
                                    <Info styles="mt-4" label="LOSS CALCULATION"
                                          value="See Covered Loss Schedule in Appendix"/>
                                    <Info styles="mt-4" label="REFERENCE DATA"
                                          value="Daily temperature minima from ECMWF ERA5-LAND gridded weather model"/>
                                    <Info styles="mt-4" label="FDD THRESHOLD (°F)" value="32"/>
                                    <Info styles="mt-4" label="DAILY CAP (FDD)" value="7.3"/>
                                </div>}

                                <div className="mt-8">
                                    <h3 className="text-lg font-bold">Location</h3>
                                    <h3 className="mb-2">{quote.site.name} ({quote.site.latitude},{quote.site.longitude})</h3>
                                    <LocationMap lat={quote.site.latitude} lng={quote.site.longitude}/>
                                </div>

                                <div className="mt-10">
                                    <h3 className="text-lg font-bold">Payout Schedule</h3>
                                    <div className="mt-2 flex space-x-10">
                                        <PayoutScheduleTable rows={quote.pricing.payoutSchedule}/>

                                        <div className="">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                data={quote.pricing.payoutSchedule}
                                                margin={{
                                                    top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <XAxis dataKey="fdd"/>
                                                <YAxis/>
                                                <Tooltip/>
                                                <Legend/>
                                                <Line type="monotone" dataKey="loss" stroke="#82ca9d"/>
                                            </LineChart>
                                        </div>
                                    </div>

                                </div>
                                <div className="mt-10">
                                    <h3 className="text-lg font-bold">Claim History</h3>
                                    <div className="mt-2">
                                        <ClaimHistoryTable claimHistory={quote.pricing.claimHistory}/>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <p className="text-xs">
                                    *Disclaimer: This tool enables for instant quotes of several Worldcover's parametric
                                    insurance products. Although our premium estimates rely on highest standard in
                                    actuarial science applied to parametric insurance, there are only indicative and
                                    neither worldcover nor its insurance and reinsurance partner can guarantee that the
                                    covers could be purchased at the estimated price. In case the client would confirm
                                    its interest for one of the structure and its estimated price, worldcover will work
                                    with broker partners and (re)insurers to get the best firm offer as soon as
                                    possible.
                                </p>
                            </div>


                        </div>
                    )
                }

            </div>
        </div>);
}