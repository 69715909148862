import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useHistory, useLocation} from "react-router-dom";
import {v4 as uuid} from 'uuid';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {DatePicker} from '@material-ui/pickers';

import AmountTextField from '../components/AmountTextField';
import {RootState, useAppDispatch} from '../reducers';
import LocationMap from "../components/LocationMap";
import {ProductSelector} from "../components/ProductSelector";
import {addQuote, baseQuote, Quote, QuoteStatus} from '../reducers/quotesSlice';
import {API, graphqlOperation} from "aws-amplify";
import {createQuote} from "../graphql/mutations";

const useStyles = makeStyles((theme) => ({
    title: {marginLeft: theme.spacing(4), marginTop: theme.spacing(2)},
    root: {
        flexGrow: 1,
    },
    margin: {
        margin: theme.spacing(1),
    },
    map: {marginTop: theme.spacing(2)},
    actionWrapper: {
        margin: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    error: {color: theme.palette.error.main},
    button: {maxWidth: 450, padding: '16px'},
    paper: {
        // height: '100%',
        padding: theme.spacing(4
        ),
        marginTop: theme.spacing(4),
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 300,
    },
    summaryWrapper: {
        width: '100%',
        padding: theme.spacing(4),
    },
}));

export interface Crop {
    id: string;
    name: string;
    icon?: string;
    products: string[];
}

export interface Product {
    id: string;
    name: string;
    cropId: string;
}

export const products: { [key: string]: Product } = {
    'walnut-frost': {
        id: 'walnut-frost',
        name: 'Walnut Frost',
        cropId: 'walnut',
    },
    'walnut-drought': {
        id: 'walnut-drought',
        name: 'Walnut Drought',
        cropId: 'walnut',
    },
    'walnut-excess-rain': {
        id: 'walnut-excess-rain',
        name: 'Walnut Excess Rain',
        cropId: 'walnut',
    },
    'almond-frost': {
        id: 'almond-frost',
        name: 'Almond Frost',
        cropId: 'almond',
    },
    'almond-drought': {
        id: 'almond-drought',
        name: 'Almond Drought',
        cropId: 'almond',
    },
    'citrus-frost': {
        id: 'citrus-frost',
        name: 'Citrus Frost',
        cropId: 'citrus',
    },
    'pistachio-frost': {
        id: 'pistachio-frost',
        name: 'Pistachio Frost',
        cropId: 'pistachio',
    },
};

export const crops: { [key: string]: Crop } = {
    'walnut': {
        id: 'walnut',
        name: 'Walnut',
        products: [
            'walnut-frost',
            'walnut-drought',
        ],
    },
    'almond': {
        id: 'almond',
        name: 'Almond',
        products: ['almond-frost', 'almond-drought'],
    },
    'citrus': {
        id: 'citrus',
        name: 'Citrus',
        products: ['citrus-frost'],
    },
    'pistachio': {
        id: 'pistachio',
        name: 'Pistachio',
        products: ['pistachio-frost'],
    },
};

export default function QuoteForm() {
    const dispatch = useAppDispatch();
    const query = useQuery();
    const classes = useStyles();
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const {byId: quotesById} = useSelector((state: RootState) => state.quotes);

    const quoteId = query.get("revise");
    const quoteToBeRevised = quoteId ? quotesById[quoteId] : null;

    const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

    useEffect(() => {
        if (quoteToBeRevised) setSelectedProductId(quoteToBeRevised.product);
    }, [quoteToBeRevised])

    useEffect(() => {
        // console.log('selectedProductId', selectedProductId);
    }, [selectedProductId])

    const [errorText, setError] = useState<string>();

    const [company, setCompany] = useState<string>(quoteToBeRevised?.client ?? '');
    const [anchorValue, setAnchorValue] = useState<number>(quoteToBeRevised?.sumInsuredPerAcre ?? 1000);

    const [acreage, setAcreage] = useState<number>(quoteToBeRevised?.acreage ?? 200);

    const [crop, setCrop] = useState<string>(quoteToBeRevised?.crop ?? '');
    const [peril, setPeril] = useState<string>('');

    const [location, setLocation] = useState(
        {
            name: quoteToBeRevised?.site.name ?? '',
            lat: quoteToBeRevised?.site.latitude ?? 39.64,
            lng: quoteToBeRevised?.site.longitude ?? -121.82,
        });

    const [startDate, setStartDate] = useState(
        quoteToBeRevised && quoteToBeRevised.riskPeriod ? moment(quoteToBeRevised.riskPeriod.start) : moment('2020-02-08'),
    );
    const [endDate, setEndDate] = useState(
        quoteToBeRevised && quoteToBeRevised.riskPeriod ? moment(quoteToBeRevised.riskPeriod.end) : moment('2020-03-10'),
    );

    const requestQuote = async () => {
        if (selectedProductId == null) {
            setError('You need to select a product.')
            return;
        }

        const quote = {
            ...baseQuote,
            id: uuid(),
            status: 'pending' as QuoteStatus,
            initiated_at: new Date().toISOString(),
            pricing: undefined,
            client: company,
            crop: crop,
            product: selectedProductId,
            sumInsuredPerAcre: anchorValue,
            acreage: acreage,
            submittedTo: [],
            riskPeriod: {
                start: startDate.toISOString(),
                end: endDate.toISOString(),
            },
            site: {
                latitude: location.lat,
                longitude: location.lng,
                name: location.name,
            },
        };

        // dispatch(addQuote(quote));

        function mapQuoteToInput(quote:Quote) {
            return {
                product: quote.product,
                status: quote.status,
                initiated_at: quote.initiated_at,
                site: quote.site,
                client: quote.client,
                sumInsuredPerAcre: quote.sumInsuredPerAcre,
                acreage: quote.acreage,
                riskPeriod: quote.riskPeriod,
            }
        }

        try {
            await API.graphql(graphqlOperation(createQuote, {input: mapQuoteToInput(quote)}))
        } catch (e) {
            console.error(e);
        }

        history.push('/quotes');
    }

    return (
        <div className="m-auto max-w-5xl flex">
            <div className={classes.root}>
                <h2 className="my-8 text-4xl font-semibold">Request Quote</h2>

                <Paper className={`${classes.paper} mt-8 p-8`}>
                    <ProductSelector value={selectedProductId}
                                     onChange={(productId) => {
                                         setSelectedProductId(productId);
                                     }}
                    />
                </Paper>

                <Paper className={classes.paper}>
                    <Typography variant="h5">Basic Information</Typography>
                    <TextField
                        className={classes.textField}
                        value={company}
                        onChange={(ev) => setCompany(ev.target.value)}
                        label="Client Name"
                        placeholder="Acme Agro"
                        variant="outlined"
                    />
                    <br/>
                    <AmountTextField
                        label="Sum Insured / Acre"
                        value={anchorValue}
                        className={classes.textField}
                        variant="outlined"
                        onChange={(ev) => setAnchorValue(parseInt(ev.target.value, 10))}
                    />
                    <TextField
                        label="Acreage"
                        value={acreage}
                        className={classes.textField}
                        variant="outlined"
                        onChange={(ev) => setAcreage(parseInt(ev.target.value, 10))}
                    />
                    <br/>
                    <div className="mt-6">
                        <div className="uppercase text-sm">Sum Insured</div>
                        <div className="text-2xl">$ {(anchorValue * acreage).toLocaleString()}</div>
                    </div>
                </Paper>
                <Paper className={classes.paper}>
                    <Typography variant="h5">Coverage Period</Typography>
                    <DatePicker
                        label="Start Date"
                        className={classes.textField}
                        onChange={(date) => date && !Array.isArray(date) && setStartDate(date)}
                        value={startDate}
                    />
                    <DatePicker
                        label="End Date"
                        className={classes.textField}
                        value={endDate}
                        onChange={(date) => date && !Array.isArray(date) && setEndDate(date)}
                    />
                    <div className="mt-8 text-xl">Duration: {endDate.diff(startDate, 'days', true)} days</div>
                </Paper>

                <Paper className={classes.paper}>
                    <Typography variant="h5">Production Site</Typography>

                    <TextField
                        className={classes.textField}
                        value={location.lat}
                        label="Latitude"
                        variant="outlined"
                        type="number"
                        onChange={(ev) => {
                            const lat = parseFloat(ev.target.value);
                            if (!isNaN(lat)) setLocation({...location, lat});
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        value={location.lng}
                        label="Longitude"
                        variant="outlined"
                        type="number"
                        onChange={(ev) => {
                            const lng = parseFloat(ev.target.value);
                            if (!isNaN(lng)) setLocation({...location, lng});
                        }}

                    />
                    <TextField
                        className={classes.textField}
                        value={location.name}
                        label="Site Name (optional)"
                        variant="outlined"
                        placeholder="Site 201"
                        onChange={(ev) => setLocation({...location, name: ev.target.value})}
                    />
                    <div className={classes.map}>
                        <LocationMap lat={location.lat} lng={location.lng}/>
                    </div>
                </Paper>

                <div className="mt-10 mb-20 text-center">
                    {errorText && (
                        <div className="text-xl text-red-600 my-10">
                            {errorText}
                        </div>
                    )}
                    <Button
                        className={classes.button}
                        onClick={requestQuote}
                        variant="contained"
                        color="primary">
                            <span className="text-white">
                                Request a Quote
                            </span>
                    </Button>
                </div>
            </div>
        </div>
    );
}
